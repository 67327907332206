<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Tgl. Booking *</label>
          <vs-input class="w-full" v-model="booking.tgl" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">No. Booking</label>
          <vs-input class="w-full" v-model="booking.no_kontrak" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Staf Marketing</label>
          <vs-input class="w-full" v-model="booking.nama_staf" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Koordinator</label>
          <vs-input class="w-full" v-model="booking.nama_staf_atasan" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <label class="ml-1 text-xs">Keterangan Booking</label>
          <vs-textarea class="w-full" v-model="booking.keterangan_booking"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Proyek</label>
          <vs-input class="w-full" v-model="booking.nama_proyek" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Properti Kategori</label>
          <vs-input class="w-full" v-model="booking.nama_properti_kategori" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">Properti Tipe</label>
          <vs-input class="w-full" v-model="booking.nama_properti_tipe" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">Cluster</label>
          <vs-input class="w-full" v-model="booking.nama_cluster" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">Properti Unit</label>
          <vs-input class="w-full" v-model="booking.no_unit" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Luas Bangunan</label>
          <vs-input class="w-full" v-model="properti.luas_bangunan" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Luas Tanah</label>
          <vs-input class="w-full" v-model="properti.luas_tanah" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Luas Efektif</label>
          <vs-input class="w-full" label-placeholder="" v-model="properti.luas_efektif" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">View</label>
          <vs-input class="w-full" v-model="properti.view" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Lokasi</label>
          <vs-input class="w-full" v-model="properti.lokasi" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Keterangan</label>
          <vs-input class="w-full" v-model="properti.keterangan" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Spesifikasi</label>
          <vs-input class="w-full" v-model="properti.spesifikasi" readonly/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'

export default {
  name: 'TabProperti',
  props: ['isActive'],
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  computed: {
    storeBookingDetail () {
      return this.$store.state.marketing.bookingDetail
    },
    booking () {
      return this.storeBookingDetail.booking
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      properti: {}
    }
  },

  methods: {
    initData () {
      this.getProperti()
      this.isDataInited = true
    },

    getProperti () {
      this.isLoading = true

      const idPropertiUnit = this.booking.id_properti_unit
      PropertiUnitRepository.show(idPropertiUnit)
        .then(response => {
          this.properti = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
